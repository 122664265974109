import React from "react";
import {Link} from "gatsby";

import billionairesRow from "../works/BillionairesRow/main.png";
import celestia from "../works/Celestia/main.png";
import biocow from "../works/Biocow/main.png";
import glassview from "../works/Glassview/hero.png";
import welcomech from "../works/WelcomeCH/main.png";

import mwc from "../works/MWC/main.png";
import isb from "../works/ISB/main.png";
import cudos from "../works/Cudos/main.png";
import cogwear from "../works/Cogwear/main.png";
import continuum from "../works/Continuum/main.png";
import aisb1 from "../works/Aisb/aisb-main-1.png";
import aisb2 from "../works/Aisb/aisb-main-2.png";
import aisb3 from "../works/Aisb/aisb-main-3.png";
import aisb4 from "../works/Aisb/aisb-main-4.png";
import aisb5 from "../works/Aisb/aisb-main-5.png";
import tallys1 from "../works/Tallys/tallys-main-1.png";
import tallys2 from "../works/Tallys/tallys-main-2.png";
import tallys3 from "../works/Tallys/tallys-main-3.png";
import tallys4 from "../works/Tallys/tallys-main-4.png";
import tallys5 from "../works/Tallys/tallys-main-5.png";
import gritco1 from "../works/TheGritCompany/gritco-main-1.png";
import gritco2 from "../works/TheGritCompany/gritco-main-2.png";
import gritco3 from "../works/TheGritCompany/gritco-main-3.png";
import gritco4 from "../works/TheGritCompany/gritco-main-4.png";
import gritco5 from "../works/TheGritCompany/gritco-main-5.png";
import sg1 from "../works/SG-1/main.png";
import talusPoster from "../works/Talus/work.png";
import ibcPoster from "../works/Ideathon/main.png";
import nesaPoster from "../works/Nesa/main.png";


import WorkArrow from "./workArrow";

import InterProtocolMp4 from "../works/InterProtocol/video/main.mp4";
import InterProtocol from "../works/InterProtocol/video/main.webm";
import AbleMp4 from "../works/Able/video/main.mp4";
import Able from "../works/Able/video/main.webm";
import TurfgrassMp4 from "../works/Turfgrass/video/logoAnim.mp4";
import Turfgrass from "../works/Turfgrass/video/logoAnim.webm";
import AgoricMp4 from "../works/Agoric/video/main.mp4";
import Agoric from "../works/Agoric/video/main.webm";
import ImageSlider from "./imageSlider";

class Gallery extends React.Component {
    render() {
        return (
            <div className={'dn-grid'}>
                <div className={'grid-p50 item item-black'}>
                    <Link to={'our-work/nesa'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} poster={nesaPoster} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                <source src={"https://player.vimeo.com/progressive_redirect/playback/1010712122/rendition/540p/file.mp4?loc=external&signature=632ad182f84672c40bb5fe0cfde301fd21c0de34f278237250df80b48475f5d0"}/>
                            </video>
                            <div className={'title'}>
                                NESA
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-p50 item item-right'} style={{aspectRatio:'66/43'}}>
                    <Link to={'our-work/talus'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} poster={talusPoster} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                <source src={"https://player.vimeo.com/progressive_redirect/playback/1007358986/rendition/480p/file.mp4?loc=external&signature=35a0799ee9b830654657a468762816b15ff9372c7c6057606490071a5a87a999"}/>
                            </video>
                            <div className={'title'}>
                                Talus
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-right'}>
                    <Link to={'our-work/cudos'}>
                        <div className={'image'}>
                            <img src={cudos} alt="Cudos"/>
                            <div className={'title'}>
                                Cudos
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-black item-left'}>
                    <Link to={'our-work/international-school-of-belgrade'} target={'_blank'}>
                        <div className={'image'}>
                            <img src={isb} alt="ISB"/>
                            <div className={'title'}>
                                ISB
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-black item-right'}>
                    <Link to={'our-work/ideathon'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} poster={ibcPoster} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                <source src={'https://player.vimeo.com/progressive_redirect/playback/1025056812/rendition/480p/file.mp4?loc=external&signature=245a92c49963d66378fbbfdca2c3e9be27f9249b9321ec77133ae273e2de87ee'}/>
                            </video>
                            <div className={'title'}>
                                IBC Ideathon
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-white item-left'}>
                    <Link to={'our-work/the-grit-company'}>
                        <div className={'image'}>
                            <ImageSlider time={4} images={
                                [gritco1, gritco2, gritco3, gritco4, gritco5]
                            }/>
                            <div className={'title'}>
                                The Grit Company
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c66 item item-black item-right'}>
                    <Link to={'our-work/cogwear'}>
                        <div className={'image'}>
                            <img src={cogwear} alt="Cogwear"/>
                            <div className={'title'}>
                                Cogwear
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-right item-black'}>
                    <Link to={'our-work/continuum-collective'}>
                        <div className={'image'}>
                            <img src={continuum} alt="Continuum Collective"/>
                            <div className={'title'}>
                                Continuum
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-left item-black'}>
                    <Link to={'our-work/tallys'}>
                        <div className={'image'}>
                            <ImageSlider time={3} images={
                                [tallys1, tallys2, tallys3, tallys4, tallys5]
                            }/>

                            <div className={'title'}>
                                Tallys
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-left item-black'}>
                    <Link to={'our-work/able'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={AbleMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Able} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Able
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-right item-black'}>
                    <Link to={'our-work/american-international-school-of-budapest'}>
                        <div className={'image'}>
                            <ImageSlider time={6} images={
                                [aisb1, aisb2, aisb3, aisb4, aisb5]
                            }/>

                            <div className={'title'}>
                                AISB
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c66 item item-left item-black'}>
                    <Link to={'our-work/sg-1'}>
                        <div className={'image'}>
                            <img src={sg1} alt="SG-1"/>
                            <div className={'title'}>
                                SG-1
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c66 item item-right'}>
                    <Link to={'our-work/glassview'}>
                        <div className={'image'}>
                            <img src={glassview} alt="Glassview"/>
                            <div className={'title'}>
                                Glassview
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black item-left'}>
                    <Link to={'our-work/pannon-turfgrass'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={TurfgrassMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Turfgrass} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Turfgrass
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <Link to={'our-work/biocow'}>
                        <div className={'image'}>
                            <img src={biocow} alt="Able"/>
                            <div className={'title'}>
                                Biocow
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c66 item item-black item-right'}>
                    <Link to={'our-work/billionaires-row'}>
                        <div className={'image'}>
                            <img src={billionairesRow} alt="Billionaires Row"/>
                            <div className={'title'}>
                                Billionaires Row
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-left'}>
                    <Link to={'our-work/inter-protocol'}>
                        <div className={'image'} style={{aspectRatio:'43/28'}}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                <source src={InterProtocolMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={InterProtocol} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Inter Protocol
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-left'}>
                    <Link to={'our-work/celestia'}>
                        <div className={'image'}>
                            <img src={celestia} alt="Celestia"/>
                            <div className={'title'}>
                                Celestia
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black item-left'}>
                    <Link to={'our-work/mwc'}>
                        <div className={'image'}>
                            <img src={mwc} alt="MWC"/>
                            <div className={'title'}>
                                MWC
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-left item-black'}>
                    <Link to={'our-work/welcomech'}>
                        <div className={'image'}>
                            <img src={welcomech} alt="Welcome CH"/>
                            <div className={'title'}>
                                Welcome CH
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-left'}>
                    <Link to={'our-work/agoric'}>
                        <div className={'image'}>
                            <div className={'overlay'}></div>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={AgoricMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Agoric} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Agoric
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c66 item item-right'}>
                    <Link to={'our-work/'}>
                        <div className={'image'}>
                            <button type={'button'} className={'btn mt-3'}>
                                <WorkArrow/>
                                All our work
                            </button>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Gallery;
