import * as React from "react"
import {Link} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import Partners from "../sections/partners";

import { StaticImage } from "gatsby-plugin-image"

import Gallery from "../components/gallery";
import WorkArrow from "../components/workArrow";
import ImageSlider from "../components/imageSlider";
import team01 from "../images/team/team-0-1.png";
import team02 from "../images/team/team-0-2.png";
import team03 from "../images/team/team-0-3.png";
import team04 from "../images/team/team-0-4.png";
import team05 from "../images/team/team-0-5.png";
import HomeServices from "../components/homeServices";

const IndexPage = ({...props}) => {

    const title = 'Branding, Web Design, Motion & More | Creative Agency in Budapest, Hungary | Designatives';
    const description = 'We are Designatives, a creative agency in Budapest, Hungary specializing in branding, web design and development, logos, UI/UX design, motion design, and video production. Let’s bring your ideas to life.';
  return (
    <main>
        <Helmet>
            <title>{title}</title>

            <meta name="description" content={description}/>
            <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8"/>
            <meta name="format-detection" content="telephone=no"/>
            <meta name="theme-color" content="#fff"/>

            <link rel="schema.dcterms" href="http://purl.org/dc/terms/"/>
            <meta name="DC.coverage" content="Hungary"/>
            <meta name="DC.description" content={description}/>
            <meta name="DC.format" content="text/html"/>
            <meta name="DC.identifier" content="http://www.designatives.com"/>
            <meta name="DC.publisher" content={title}/>
            <meta name="DC.title" content={title}/>
            <meta name="DC.type" content="Text"/>

            <meta property="fb:app_id" content="355084464688689"/>
            <meta property="og:url" content="https://designatives.com/"/>
            <meta property="og:type" content=""/>
            <meta property="og:title" content="Designatives"/>
            <meta property="og:site_name" content="Designatives"/>
            <meta property="og:description" content={description}/>
            <meta property="og:site_name" content={title}/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@designatives"/>
            <meta name="twitter:title" content="Designatives"/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:creator" content="@designatives"/>

            <script type="text/javascript" async="" src="https://statcounter.com/counter/recorder.js"/>
            <script async="" src="https://www.googletagmanager.com/gtm.js?id=G-Y42NC1XHMP"/>
            <script async="" id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="9dbd0118-ce98-40f7-851d-c513aa24ff40" data-blockingmode="auto" type="text/javascript"/>
        </Helmet>

        <Header/>

        <Layout>
            <section className={'hero-section'}>
                <div className={'container'}>
                    <h1 className={'level1'}>
                        This is Designatives. <br/>
                        This is what we do.
                    </h1>

                    <div className={'d-md-none'}>
                        <a href="https://calendly.com/designatives/30-minute-meeting" target={'_blank'} rel={'noreferrer'}>
                            <div className={'btn'}>
                                <WorkArrow/>
                                Book a meeting
                            </div>
                        </a>
                    </div>
                </div>
            </section>

            <section className="work-section">
                <div className="container">
                    <div className="row">
                        <div className={'col-12'}>
                            <Gallery/>
                        </div>
                    </div>
                </div>
            </section>

            <Partners/>

            <section className="team-section at-home">
                <div className="container">
                    <div className="row">
                        <div className={'col-12'}>
                            <h2>This is who we are.<br/>
                                And there’s so much more.</h2>

                            <div className={'dn-grid'}>
                                <div className={'grid-p50 item item-black'}>
                                    <div className={'image'}>
                                        <ImageSlider time={9} images={[
                                            team01, team02, team03, team04, team05
                                        ]}/>
                                    </div>
                                </div>
                                <div className={'grid-p50 item item-right item-black'}>
                                    <div className={'image ratio'} style={{aspectRatio: "128/83"}}>
                                        <video id={'team-video-5'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                            <source src={'https://player.vimeo.com/progressive_redirect/playback/1006384418/rendition/720p/file.mp4?loc=external&signature=3dbd01745eeb81f5a6ad45b3ca7d9c0c4c4362027eaf86ce52369abfe96abe67'}/>
                                        </video>
                                    </div>
                                </div>
                                <div className={'grid-p50 item item-right'}>
                                    <Link to={'/about-us'}>
                                        <div className={'btn mt-3'}>
                                            <WorkArrow/>
                                            More
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HomeServices/>

        </Layout>
        <Footer/>
    </main>
  )
}

export default IndexPage
